import { Route, Routes } from "react-router-dom";

import AuthenticatedRoute from "./features/auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./features/auth/UnauthenticatedRoute";
import Admin from "./containers/Admin";
import BecomeSponsor from "./containers/BecomeSponsor";

import Home from "./containers/Home";
import Login from "./features/auth/Login";
import NotFound from "./containers/NotFound";
import Signup from "./features/auth/Signup";
import EventsPage from "./features/events/EventsPage";
import Profile from "./features/auth/Profile";

export default function SiteRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />
      <Route path="/become-sponsor" element={<BecomeSponsor />} />
      <Route path="/events" element={<EventsPage />} />
      <Route
        path="/signup"
        element={
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <AuthenticatedRoute>
            <Profile />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <AuthenticatedRoute>
            <Admin />
          </AuthenticatedRoute>
        }
      />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
