import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import LoaderButton from "../../components/LoaderButton";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import { getUser } from "../../lib/apiLib";
import "./Login.css";
import { useLogin } from "./hooks";
import { createUser } from "../../lib/apiLib";
import { setFirstName, setLastName } from "../profile/profileSlice";
import { useAppDispatch } from "../../app/hooks";

export default function Login() {
  const dispatch = useAppDispatch();
  const [dispatchLogin] = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const user = await dispatchLogin(fields.email, fields.password).unwrap();
      console.log("Successful login");
      console.log(user);

      // successful login
      const dbUser = await getUser({
        cognitoId: user.cognitoId,
        email: fields.email,
      });
      if (dbUser === undefined || dbUser === "") {
        console.log("Creating the user in the DB");
        const resp = await createUser({
          cognitoId: user.cognitoId,
          email: fields.email,
          firstName: "",
          lastName: "",
        });
        console.log(resp);
      }
      if (dbUser !== undefined) {
        dispatch(setFirstName(dbUser.firstName));
        dispatch(setLastName(dbUser.lastName));
      }
    } catch (e: any) {
      console.log("Error while logging in");
      console.log(e);
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>
    </div>
  );
}
