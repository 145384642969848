export default function BecomeSponsor() {
  return (
    <>
      <p>
        Corporate Sponsorship is available to help the Wisconsin River Runners
        promote the sport of ATV and UTV Riding. Monies will be used in trail
        development, promoting safe ATV and UTV Riding and help bring business
        to businesses.
      </p>

      <p>
        Sponsorship runs annually, January through December. Sponsorship
        includes your Logo on our website and recognition in our newsletter.
      </p>

      <p>Sponsorship Costs:</p>
      <p>$100 a year for Platinum level</p>

      <p>$50 a year for Gold level</p>

      <p>$25 a year for Silver level</p>

      <p>Interested?</p>
    </>
  );
}

// TODO: Make this a table with the sponsor benefits
