import { useGetAuthState } from "./hooks";

interface Props {
  requiredGroups: string[];
  children: JSX.Element;
}

function AuthGroupWrapper({ requiredGroups, children }: Props) {
  const [authState] = useGetAuthState();
  const userGroups = authState.groups;

  const shouldRender = () => {
    if (!userGroups) {
      return false;
    }
    const intersectingGroups = userGroups.filter((g) =>
      requiredGroups.includes(g)
    );
    return intersectingGroups.length > 0;
  };

  return <>{shouldRender() && children}</>;
}

export default AuthGroupWrapper;
