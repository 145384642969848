import { Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";
import Families from "./Families";
import Dashboard from "./Dashboard";
import MailList from "./MailList";
import AuthenticatedRoute from "../../features/auth/AuthenticatedRoute";
import Users from "./Users";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/families"
        element={
          <AuthenticatedRoute>
            <Families />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <AuthenticatedRoute>
            <Users />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/mail-list"
        element={
          <AuthenticatedRoute>
            <MailList />
          </AuthenticatedRoute>
        }
      />
      {/* Finally, catch all unmatched routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
