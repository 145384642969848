import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Root } from "react-dom/client";
import type { RootState } from "../../app/store";

export interface ProfileState {
  firstName?: string;
  lastName?: string;
  email?: string;
}

const initialState: ProfileState = {
  firstName: undefined,
  lastName: undefined,
  email: undefined,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    clearProfile: (state) => {
      state = initialState;
    },
  },
});

export const { setFirstName, setLastName, clearProfile } = profileSlice.actions;

export const getProfile = (state: RootState) => state.profile;
export const getFirstName = (state: RootState) => state.profile.firstName;

export default profileSlice.reducer;
