import React from "react";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Avatar } from "rsuite";
import UserIcon from "@rsuite/icons/legacy/User";
import AuthGroupWrapper from "../auth/AuthGroupWrapper";
import { useAppSelector } from "../../app/hooks";
import { getFirstName } from "../profile/profileSlice";
import {
  useIsAuthenticated,
  useLoadUserSession,
  useLogout,
} from "../auth/hooks";

export default function MenuBar() {
  const [loadUserSession] = useLoadUserSession();
  const [isAuthenticated] = useIsAuthenticated();
  const [dispatchLogout] = useLogout();
  const firstName = useAppSelector(getFirstName);

  if (!isAuthenticated) {
    console.log("Loading user session");
    loadUserSession();
  }

  function renderUserIcon() {
    const letter = firstName?.slice(0, 1);
    if (isAuthenticated && letter !== undefined) {
      return letter;
    }
    return <UserIcon />;
  }

  async function handleLogout() {
    await dispatchLogout();
  }

  return (
    <Navbar
      collapseOnSelect
      bg="dark"
      variant="dark"
      expand="md"
      className="mb-3"
      sticky="top"
    >
      <MenuItem to="/home" type="BRAND">
        WI River Runners
      </MenuItem>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content">
        <Nav
          variant="pills"
          activeKey={window.location.pathname}
          defaultActiveKey="/home"
        >
          <MenuItem to="/home" type="LINK">
            Home
          </MenuItem>
          <MenuItem to="/sponsers" type="LINK">
            Sponsors
          </MenuItem>
          <MenuItem to="/become-sponsor" type="LINK">
            Become a Sponsor
          </MenuItem>
          <MenuItem to="/events" type="LINK">
            Events
          </MenuItem>
          <MenuItem to="/photos" type="LINK">
            Photos
          </MenuItem>
          <MenuItem to="/admin" type="LINK" requiredGroups={["admin"]}>
            Admin
          </MenuItem>
        </Nav>
      </Navbar.Collapse>

      <Dropdown as={Nav.Item}>
        <Dropdown.Toggle as={Nav.Link}>
          <Avatar>{renderUserIcon()}</Avatar>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {isAuthenticated ? (
            <>
              <MenuItem to="/profile" type="DROPDOWN">
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout} type="DROPDOWN">
                Logout
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem to="/signup" type="DROPDOWN">
                Signup
              </MenuItem>
              <MenuItem to="/login" type="DROPDOWN">
                Login
              </MenuItem>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  );
}

type OnClickFunc = (event: any) => {};

interface IMenuItemProps {
  children: JSX.Element | string;
  requiredGroups?: string[];
  type: "DROPDOWN" | "LINK" | "BRAND";
  to?: string;
  onClick?: OnClickFunc;
}

function MenuItem(props: IMenuItemProps) {
  let item: React.ReactNode;
  switch (props.type) {
    case "DROPDOWN":
      item = (
        <Dropdown.Item onClick={props.onClick}>{props.children}</Dropdown.Item>
      );
      break;
    case "LINK":
      item = <Nav.Link onClick={props.onClick}>{props.children}</Nav.Link>;
      break;
    case "BRAND":
      item = (
        <Navbar.Brand
          className="font-weight-bold text-muted"
          onClick={props.onClick}
        >
          {props.children}
        </Navbar.Brand>
      );
      break;
  }

  if (props.to !== undefined) {
    // Wrap in a LinkContainer to work with react-router
    item = (
      <LinkContainer to={props.to} key={props.to.replaceAll("/", "")}>
        {item}
      </LinkContainer>
    );
  }
  if (props.requiredGroups !== undefined) {
    // Wrap it in an AuthGroupWrapper to hide if they are not in the group
    item = (
      <AuthGroupWrapper requiredGroups={props.requiredGroups}>
        {item}
      </AuthGroupWrapper>
    );
  }
  return item;
}
