const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN!,
  apiGateway: {
    REGION: process.env.REACT_APP_REGION!,
    URL: process.env.REACT_APP_API_URL!,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION!,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID!,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID!,
  },
  LOGROCKET_ID: process.env.REACT_APP_LOGROCKET_ID!,
};

export default config;
