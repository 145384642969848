import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated } from "./hooks";

interface Props {
  children: JSX.Element;
}

export default function AuthenticatedRoute({ children }: Props): JSX.Element {
  const { pathname, search } = useLocation();
  const [isAuthenticated] = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  return children;
}
