import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import "./LoaderButton.css";

type LoaderButtonProps = {
  block: string;
  isLoading: boolean;
  disabled: boolean;
  children: React.ReactNode;
};

const LoaderButton: React.FunctionComponent<
  LoaderButtonProps & ButtonProps
> = ({ isLoading, className = "", disabled = false, ...props }) => {
  return (
    <Button
      disabled={disabled || isLoading}
      className={`LoaderButton ${className}`}
      {...props}
    >
      {isLoading && <BsArrowRepeat className="spinning" />}
      {props.children}
    </Button>
  );
};

export default LoaderButton;
