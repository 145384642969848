import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
import "./App.css";
import MenuBar from "./features/menu/MenuBar";
import "rsuite/dist/rsuite.min.css";

function App() {
  return (
    <div className="app">
      <MenuBar />
      <div className="content container-fluid">
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default App;
