import { Col, Container, Nav, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "../../components/ErrorBoundary";
import AdminRoutes from "./AdminRoutes";

export default function Admin() {
  return (
    <Container>
      <Row>
        <Col md="auto" xs lg="auto">
          <Nav justify variant="tabs" className="flex-column">
            <LinkContainer to="/admin">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/families">
              <Nav.Link>Families</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/users">
              <Nav.Link>Users</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/mail-list">
              <Nav.Link>Mail List</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/admin/messages">
              <Nav.Link>Messages</Nav.Link>
            </LinkContainer>
          </Nav>
        </Col>
        <Col>
          <ErrorBoundary>
            <AdminRoutes />
          </ErrorBoundary>
        </Col>
      </Row>
    </Container>
  );
}
