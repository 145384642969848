import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import config from "../config";

const isLocal = process.env.NODE_ENV === "development";

interface Extras {
  [key: string]: any;
}

export function initSentry() {
  if (isLocal) {
    return;
  }

  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

export function logError(error: Error, errorInfo: Extras) {
  if (isLocal) {
    console.log(error);
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export function onError(error: any) {
  let errorInfo: Extras = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  if (message === "User does not exist.") {
    message = "Incorrect username or password";
  }
  alert(message);
}
