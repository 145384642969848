import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import type { RootState } from "../../app/store";

export const loadUserSession = createAsyncThunk(
  "auth/loadUserSession",
  async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return {
        cognitoId: user.username,
        groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        token: user.signInUserSession.accessToken.jwtToken,
      };
    } catch (err) {
      throw err;
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  try {
    await Auth.signOut();
  } catch (err) {
    throw err;
  }
});

export const login = createAsyncThunk(
  "auth/login",
  async (creds: { email: string; password: string }) => {
    try {
      //
      const user = await Auth.signIn(creds.email, creds.password);
      return {
        cognitoId: user.username,
        groups: user.signInUserSession.accessToken.payload["cognito:groups"],
        token: user.signInUserSession.accessToken.jwtToken,
      };
    } catch (err) {
      throw err;
    }
  }
);

export interface AuthState {
  cognitoId?: string;
  groups: string[];
  token?: string;
}

const initialState: AuthState = {
  cognitoId: undefined,
  groups: [],
  token: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<AuthState>) => {
      state.cognitoId = action.payload.cognitoId;
      state.groups = action.payload.groups;
      state.token = action.payload.token;
    },
    setCognitoId: (state, action: PayloadAction<string>) => {
      state.cognitoId = action.payload;
    },
    setGroups: (state, action: PayloadAction<string[]>) => {
      state.groups = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearLogin: (state) => {
      state.cognitoId = undefined;
      state.groups = [];
      state.token = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserSession.fulfilled, (state, action) => {
      state.cognitoId = action.payload.cognitoId;
      state.groups = action.payload.groups;
      state.token = action.payload.token;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.cognitoId = action.payload.cognitoId;
      state.groups = action.payload.groups;
      state.token = action.payload.token;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.cognitoId = undefined;
      state.groups = [];
      state.token = undefined;
    });
  },
});

export const { clearLogin, setCognitoId, setGroups, setLogin, setUserToken } =
  authSlice.actions;

export const getAuthState = (state: RootState) => state.auth;
export const loginStatus = (state: RootState) =>
  state.auth.cognitoId !== undefined;
export const getCognitoId = (state: RootState) => state.auth.cognitoId;
export const getGroups = (state: RootState) => state.auth.groups;
export const getToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
