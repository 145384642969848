import React, { useCallback, useEffect, useRef, useState } from "react";
import { usersList, deleteUser } from "../../lib/apiLib";
import AuthGroupWrapper from "../../features/auth/AuthGroupWrapper";
import { onError } from "../../lib/errorLib";
import ButtonToolbar from "rsuite/ButtonToolbar";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import IconButton from "rsuite/IconButton";
import { ClipLoader } from "react-spinners";
import { useGetAuthState } from "../../features/auth/hooks";

export default function Users() {
  const isApiCalled = useRef(false);
  const [authState] = useGetAuthState();
  const isAuthenticated = authState.cognitoId !== undefined;
  const cognitoId = authState.cognitoId;
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);

  const onLoad = useCallback(async () => {
    if (!isAuthenticated || isApiCalled.current) {
      return;
    }

    setIsLoading(true);
    try {
      isApiCalled.current = true;
      const users = await usersList();
      setUserList(users);
    } catch (err: any) {
      onError(err);
    }

    setIsLoading(false);
  }, [isAuthenticated]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  async function handleDelete(user: any) {
    setIsLoading(true);
    console.log(`Delete User: ${user.cognitoId}`);
    console.log(await deleteUser(user.cognitoId));

    const users = await usersList();
    setUserList(users);
    setIsLoading(false);
  }

  function renderUser(user: any) {
    return (
      <tr>
        <td>
          {user.firstName} {user.lastName}
        </td>
        <td>{user.email}</td>
        <td>
          <AuthGroupWrapper requiredGroups={["admin"]}>
            <ButtonToolbar>
              <IconButton
                key={user.id}
                icon={<EditIcon />}
                appearance="primary"
                color="blue"
              ></IconButton>
              <IconButton
                disabled={cognitoId === user.cognitoId ? true : false}
                icon={<TrashIcon />}
                appearance="primary"
                color="red"
                onClick={() => handleDelete(user)}
              ></IconButton>
            </ButtonToolbar>
          </AuthGroupWrapper>
        </td>
      </tr>
    );
  }

  function renderUserList() {
    return (
      <div>
        <AuthGroupWrapper requiredGroups={["admin"]}>
          <>
            <div className="table-responsive">
              <table className="table table-bordered table-hover table-condensed">
                <thead>
                  <td>Name</td>
                  <td>Email</td>
                  <td></td>
                </thead>
                <tbody>
                  {userList.map((user) => {
                    return renderUser(user);
                  })}
                </tbody>
              </table>
            </div>
          </>
        </AuthGroupWrapper>
      </div>
    );
  }

  return <>{isLoading ? <ClipLoader /> : renderUserList()}</>;
}
