import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAuthState,
  loginStatus,
  loadUserSession,
  logout,
  login,
} from "./authSlice";
import { useNavigate } from "react-router-dom";

export function useIsAuthenticated() {
  const isAuthenticated = useAppSelector(loginStatus);
  return [isAuthenticated] as const;
}

export function useLoadUserSession() {
  const dispatch = useAppDispatch();
  const dispatchLoadUserSession = async () => {
    await dispatch(loadUserSession());
  };
  return [dispatchLoadUserSession] as const;
}

export function useLogin() {
  const dispatch = useAppDispatch();
  const dispatchLogin = (email: string, password: string) => {
    return dispatch(login({ email, password }));
  };
  return [dispatchLogin] as const;
}

export function useLogout() {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const dispatchLogout = () => {
    dispatch(logout());
    nav("/");
  };
  return [dispatchLogout] as const;
}

export function useGetAuthState() {
  const authState = useAppSelector(getAuthState);
  return [authState] as const;
}
