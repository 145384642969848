import React, { useCallback, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { ClipLoader } from "react-spinners";
import { useAppSelector } from "../../app/hooks";
import { loginStatus, getCognitoId } from "./authSlice";
import { onError } from "../../lib/errorLib";
import { useFormFields } from "../../lib/hooksLib";
import EditIcon from "@rsuite/icons/Edit";
import { useAppDispatch } from "../../app/hooks";
import { setFirstName, setLastName } from "../profile/profileSlice";
import { getUser, updateUser } from "../../lib/apiLib";
import LoaderButton from "../../components/LoaderButton";
import { ButtonToolbar, IconButton } from "rsuite";

interface ProfileProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  phones?: string[];
  email?: string;
}

export default function Profile() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const isApiCalled = useRef(false);
  const isAuthenticated = useAppSelector(loginStatus);
  const [editMode, setEditMode] = useState(false);
  const cognitoId = useAppSelector(getCognitoId);
  const [profile, setProfile] = useState<ProfileProps>({});
  const [fields, handleFieldChange] = useFormFields({
    firstName: "",
    lastName: "",
  });

  const onLoad = useCallback(async () => {
    if (!isAuthenticated || isApiCalled.current) {
      return;
    }

    setIsLoading(true);
    try {
      isApiCalled.current = true;
      const user = await getUser({ cognitoId: cognitoId });
      if (user !== undefined) {
        setProfile({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        });
        handleFieldChange({
          target: { id: "firstName", value: user.firstName },
        });
        handleFieldChange({ target: { id: "lastName", value: user.lastName } });
        fields.firstName = user.firstName;
        fields.lastName = user.lastName;
      }
    } catch (err: any) {
      onError(err);
    }

    setIsLoading(false);
  }, [isAuthenticated, cognitoId]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setIsUpdating(true);

    try {
      console.log(profile);
      await updateUser({
        id: profile.id!,
        firstName: fields.firstName!,
        lastName: fields.lastName!,
      });
      setProfile({
        id: profile.id,
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: profile.email,
      });
      dispatch(setFirstName(fields.firstName));
      dispatch(setLastName(fields.lastName));
      setEditMode(false);
    } catch (err: any) {
      onError(err);
    }

    setIsUpdating(false);
  }

  function validateForm() {
    return fields.firstName !== "" && fields.lastName !== "";
  }

  function renderProfile() {
    if (isLoading) {
      return <ClipLoader />;
    }
    return (
      <>
        <ButtonToolbar>
          <IconButton
            key="edit"
            icon={<EditIcon />}
            appearance="primary"
            color="blue"
            onClick={() => setEditMode(true)}
          ></IconButton>
        </ButtonToolbar>
        <table className="table table-bordered table-condensed">
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                {profile.firstName} {profile.lastName}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{profile.email}</td>
            </tr>
            <tr>
              <td>Phones</td>
              <td>{profile.phones?.map((phone) => `${phone}<br />`)}</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  function renderForm() {
    return (
      <div className="EditProfile">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              value={fields.firstName}
              as="input"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              value={fields.lastName}
              as="input"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <LoaderButton
            block="true"
            type="submit"
            size="lg"
            variant="primary"
            isLoading={isUpdating}
            disabled={!validateForm()}
          >
            Update
          </LoaderButton>
        </Form>
      </div>
    );
  }

  return !editMode ? renderProfile() : renderForm();
}
