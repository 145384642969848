import axios, { AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Auth } from "aws-amplify";
import { User } from "./types";
import config from "../config";

interface GetUserProps {
  email?: string;
  cognitoId?: string;
  id?: string;
}

interface UpdateUserProps {
  id: string;
  firstName: string;
  lastName: string;
}

let CLIENT: AxiosInstance | undefined = undefined;

const getAuthHeader = (session: any): string =>
  `Bearer ${session.getAccessToken().getJwtToken()}`;

export async function createAPIClient() {
  const session = await Auth.currentSession();
  CLIENT = axios.create({
    baseURL: config.apiGateway.URL,
    headers: {
      Authorization: getAuthHeader(session),
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: false,
  });
  createAuthRefreshInterceptor(CLIENT, async (request) => {
    await createAPIClient();
    const newSession = await Auth.currentSession();
    request.response.config.headers.Authorization = getAuthHeader(newSession);
  });
  return CLIENT;
}

export async function getAPIClient() {
  if (CLIENT === undefined) {
    return await createAPIClient();
  }
  return CLIENT;
}

export async function usersList() {
  const client = await getAPIClient();

  const resp = await client.get("users");
  return resp.data;
}

export async function createUser(user: User) {
  const client = await getAPIClient();

  const resp = await client.post("users", user);
  console.log(resp);
  return resp;
}

export async function deleteUser(userId: string) {
  const client = await getAPIClient();

  const resp = await client.delete(`users/${userId}`);
  console.log(resp);
  return resp;
}

export async function getUser(props: GetUserProps) {
  const client = await getAPIClient();
  let resp;

  if (props.cognitoId !== undefined) {
    resp = await client.get(`users/${props.cognitoId}`);
    if (resp.status === 200) {
      return resp.data;
    }
  }
  if (props.id !== undefined) {
    resp = await client.get(`users/${props.id}`);
    if (resp.status === 200) {
      return resp.data;
    }
  }
  if (props.email !== undefined) {
    resp = await client.get(`users/${props.email}`);
    if (resp.status === 200) {
      return resp.data;
    }
  }
}

export async function updateUser(props: UpdateUserProps) {
  const client = await getAPIClient();
  const resp = await client.post(`users/${props.id}`, props);
  console.log(resp);
  return resp;
}
